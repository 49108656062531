.app {
  height: 100vh;
  
  display: flex;
  flex-direction: column;

  text-align: center;

  & .content {
    flex: 1;
  }
}