.map {
  position: relative;
  overflow: hidden;
  & .map-desc {
    position: absolute;
    background-image: url("../../assets/images/static/map-desc-bg.svg");

    left: 5%;
    top: 8%;
    width: 38%;
    height: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  & a {
    & div {
      position: absolute;
      cursor: pointer;

      & label {
        cursor: pointer;
        position: absolute;

        & img {
          width: 100%;
        }
      }

      & img {
        position: absolute;
        transition: opacity 250ms ease-in;

        &.hover-show {
          opacity: 0;

          &.glow {
            opacity: 1;
          }
        }

        &.hover-hide {
          opacity: 1;
        }
      }

      &:hover {
        & .hover-show {
          opacity: 1;
        }

        & .hover-hide {
          opacity: 0;
        }
      }

      &.l1 {
        top: 76.5%;
        left: 15.6%;
        height: 7%;
        width: 4%;
      }

      // &.l2 {
      //   top: 57.2%;
      //   left: 24.75%;
      //   height: 7%;
      //   width: 4%;
      // }

      &.l3 {
        top: 68.5%;
        left: 42.6%;
        height: 7%;
        width: 4%;
      }

      &.l4 {
        top: 86.85%;
        left: 46.95%;
        height: 7%;
        width: 4%;
      }

      &.r1 {
        top: 11.1%;
        left: 59.9%;
        height: 7%;
        width: 4%;
      }

      &.r2 {
        top: 17%;
        left: 80.4%;
        height: 7%;
        width: 4%;
      }

      &.r3 {
        top: 48.1%;
        left: 84.9%;
        height: 7%;
        width: 4%;
      }
    }
  }

  & .map-desc-modal {
    position: fixed;
    bottom: -150vh;
    height: 70vh;
    max-height: 70vh;
    background-color: rgba(0, 0, 0, 0.88);
    color: #fff;
    width: 100%;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
    left: 0;
    transition: all 0.3s ease-out;
    z-index: 10;

    &.show {
      bottom: 0;
    }
  }
}

.simplebar-track.simplebar-vertical {
  background-color: #fff;
  opacity: 0.5;
}
