@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Chillow;
  src: url("./assets/fonts/Chillow-Regular.7d386921.ttf");
}

@font-face {
  font-family: WorkSans;
  src: url("./assets/fonts/WorkSans-VariableFont_wght.e89d696e.ttf");
}

@font-face {
  font-family: Oliver;
  src: url("./assets/fonts/Oliver-Regular.3ed9a249.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.z-1 {
  z-index: 1;
}
