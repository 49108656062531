.hero-block {
  height: 100vh;
  
  video {
    width: 100%;
  }

  & .discord-button {
    padding: .4rem .7rem;
    font-weight: 700;
    color: #fff;

    & .icon {
      content: url(../../assets/icons/discord.svg);

      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(142deg) brightness(105%) contrast(102%);

      width: 2rem;
      height: 2rem;

      margin-right: .4rem;
    }
  }
}

@media (min-width: 200px) or (min-width: 375px),
(min-device-width: 320px) {
  .hero-block {
    & video {
      height: 100%;

      object-fit: cover;
    }

    & .text-block {
      width: 75%;
    }
  }
}

@media (min-width: 768px) {
  .hero-block {    
    & video {
      height: auto;
      object-fit: unset;
    }
  }
}