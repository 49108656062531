@mixin inset-0 {
  position: absolute;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@mixin absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin center-half {
  background-size: 100%;
  background-repeat: no-repeat;

  transition: 0.5s;
}

html {
  scroll-behavior: smooth;
}

.home {
  background-color: #000;

  //   & .main-containers {
  //     min-height: 100vh;
  //   }

  & .content-block {
    @include flex-center;
    position: relative;

    flex-direction: column;
    max-height: 100%;
  }

  & .header {
    background: none;
    font-family: "Anton", sans-serif;

    color: #fff;

    z-index: 1;
    font-size: 3rem;
    line-height: 1;

    & .subtitle {
      background-color: transparent;
      display: block;

      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    video-container &.lg {
      font-size: 3.75rem;
      line-height: 1;

      & .subtitle {
        display: block;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    }
  }

  & .greeting-block {
    position: relative;
    padding: 5rem 1rem;

    & .video-container {
      //   @include absolute-center;
      position: relative;

      & .video-player {
        width: 100%;
        height: 100%;
        position: relative;

        border-top-left-radius: 50% 15%;
        border-top-right-radius: 50% 15%;
        border-bottom-left-radius: 50% 15%;
        border-bottom-right-radius: 50% 15%;
      }
    }

    & .pause-button {
      color: #fff;
    }

    & .play-button,
    & .pause-button {
      @include absolute-center;

      cursor: pointer;

      width: 16%;
      height: 16%;
      z-index: 2;
    }

    & .content-block {
      z-index: 1;

      position: absolute;
      left: 50%;
      // bottom: 3rem;
      transform: translateX(-50%);
    }

    & .button {
      text-shadow: 0 0 4px #000;
    }

    & .main-text {
      font-family: "Anton", sans-serif;
      color: #fff;
    }
  }

  & .masked-container {
    @include inset-0;

    top: -9rem;

    mask-image: url("../../assets/images/static/waves.6ccb11c1.svg");
    mask-size: cover;

    z-index: 0;

    & .image {
      @include inset-0;

      background-size: cover;
      background-repeat: no-repeat;
    }

    & .overlay {
      @include inset-0;
    }
  }

  & .home-block {
    position: relative;

    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

    & .masked-container {
      top: -1.25rem;

      & .image {
        background-position: center;
        background-image: url("../../assets/images/static/spaceship.png");
      }

      & .overlay {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  & .desc-block {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    background-size: cover;
    & .masked-container {
      top: -1.25rem;

      & .image {
        background-image: url("../../assets/images/static/bg.png");
        background-size: auto;
        background-repeat: repeat;
      }
    }
  }

  & .god-father-block {
    position: relative;

    width: 100%;

    background-size: cover;
    & .masked-container {
      & .image {
        background-position: center;
        background-size: 100%;
      }
    }
  }

  & .story-block {
    position: relative;

    & .masked-container {
      top: -5rem;

      & .image {
        background-position: center;
        background-image: url("../../assets/images/static/trip-5.e91b5dae.png");
      }

      & .overlay {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  & .server-block {
    position: relative;

    & .masked-container {
      & .image {
        background-position: center;
        background-image: url("../../assets/images/static/server-room.png");
      }

      & .overlay {
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  & .large-desc-block {
    position: relative;

    & .masked-container {
      top: -5rem;

      & .image {
        background-position: center;
        background-image: url("../../assets/images/static/bg-doodle.png");
      }
    }
  }

  & .principles-block {
    position: relative;

    & .masked-container {
      top: -11rem;

      & .image {
        background-position: center;
        background-image: url("../../assets/images/static/Background.bfb5bb26.png");
      }
    }

    & .grid {
      margin: 5rem 2.5rem;

      & .principle {
        width: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        border: 4px rgb(1, 27, 54);
        border-radius: 0.75rem;

        background-color: rgb(1, 27, 54);
      }
    }
  }

  & .journal-block {
    position: relative;

    & .masked-container {
      top: -7rem;

      & .image {
        background-image: url("../../assets/images/static/map2.jpeg");
      }
    }

    & .journal-container {
      overflow: hidden;
    }
  }

  & .island-map-block {
    position: relative;

    padding-bottom: 15rem;

    background-image: url("../../assets/images/static/bg.png");
    background-position: 0 7.7rem;

    & .masked-container {
      & .image {
        background-image: url("../../assets/images/static/bg.png");
        background-size: auto;
        background-repeat: repeat;
      }
    }
  }

  & .disciples-block {
    position: relative;
    box-shadow: 0 0 0 #0000, 0 0 0 #0000, 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 8px 10px -6px rgba(0, 0, 0, 0.1);

    & .masked-container {
      & .image {
        background-image: url("../../assets/images/static/bg.png");
        background-position: center;
        background-size: auto;
        background-repeat: repeat;
      }
    }
  }

  & .parallax {
    & .bg-center {
      z-index: -1;
      margin-top: -6rem;
      height: 100vh;
      background-size: cover;

      mask-image: url("../../assets/images/static/waves.6ccb11c1.svg");
      mask-size: cover;
    }

    & .faqs {
      z-index: 1;
      position: relative;
      color: #fff;

      padding-bottom: 2.5rem;

      & .masked-container {
        z-index: -1;
        top: -11rem;

        & .image {
          background-image: url("../../assets/images/static/space.png");
        }

        & .overlay {
          background-color: rgba(0, 0, 0, 0);
        }
      }

      & .header {
        font-size: 3.75rem;
        line-height: 1;

        & .subtitle {
          font-size: 1.875rem;
          line-height: 2.25rem;
        }
      }

      & ul {
        margin-left: auto;
        margin-right: auto;

        list-style: none;
        padding: 0;

        & li {
          padding: 2rem;
          margin-top: 0.75rem;

          & h3 {
            font-family: "Anton", sans-serif;
            font-size: 1.25rem;
            line-height: 1.75rem;
          }

          & .answer {
            font-family: WorkSans;
            padding-top: 1.2rem;
            padding-bottom: 1rem;
            text-shadow: 3px 2px 6px #000000;
          }
        }
      }

      & .subline {
        font-family: Oliver;
        font-size: 1.125rem;

        color: rgb(244, 241, 185);

        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }

  & .slider {
    z-index: 0;

    & > * {
      background-size: auto 100%;
      background-position: 0 0;

      &:first-child {
        background-image: url("../../assets/images/components/slider/nft-slider-1.png");
        animation: scrollGrid 160s linear infinite;
      }

      &:nth-child(2) {
        background-image: url("../../assets/images/components/slider/nft-slider-2.png");
        animation: scrollGrid 180s linear infinite;
      }

      &:nth-child(3) {
        background-image: url("../../assets/images/components/slider/nft-slider-3.png");
        animation: scrollGrid 200s linear infinite;
      }
    }
  }

  .showbgcontainer {
    margin-top: -2rem;

    background-size: cover;
    background-position: center;

    mask-image: url("../../assets/images/static/waves.6ccb11c1.svg");
    mask-size: cover;

    background-image: url("../../assets/images/static/SPACE SCENE BACKGROUND.png");

    .sec {
      @include center-half;

      position: relative;
      transition: 0.5s;

      &.sec-p1 {
        background-image: url("../../assets/images/static/SPACE SCENE RESIZED_pt1_transparent0.png");
        background-position: center right;

        left: -1000px;

        &.showbg {
          left: 0px !important;
        }
      }

      &.sec-p2 {
        background-image: url("../../assets/images/static/SPACE SCENE RESIZED_pt2_transparent0.png");
        background-position: center left;

        right: -1000px;

        &.showbg {
          right: 0px !important;
        }
      }
    }
  }

  @media (min-width: 200px) or (min-width: 375px), (min-device-width: 320px) {
    .showbgcontainer {
      & .sec {
        height: 104vh;
        background-size: cover;
      }
    }

    .greeting-block {
      background-image: url("../../assets/images/static/black-texture.jpg");
      background-position: center;

      & .video-container {
        height: 100%;
        width: 100%;
      }

      & .play-button,
      & .pause-button {
        top: 50%;
      }

      & .header {
        &.lg {
          font-size: 3rem;
          line-height: 1;
        }
      }
    }

    .home-block {
      & br {
        display: none;
      }
    }

    .island-map-block {
      padding-top: 4rem;

      & .masked-container {
        top: -11rem;
      }
    }

    .parallax {
      & .faqs {
        & .header {
          font-size: 1.875rem;
          line-height: 2.25rem;

          & .subtitle {
            font-size: 1.125rem;
            line-height: 2.25rem;
          }
        }

        & .faq {
          font-size: 1rem;
          line-height: 1.5rem;
        }

        & .answer {
          font-size: 0.9rem;
          line-height: 1rem;
        }
      }

      & .bg-center {
        background-image: url("../../assets/images/static/pyramid-landing-mobile2.png");
      }
    }

    & .god-father-block {
      & .masked-container {
        & .image {
          background-image: url("../../assets/images/static/alien-landing-mobile-3.png");
        }
      }

      & .empty-block {
        height: 450px;
      }
    }
  }

  @media (min-width: 768px) {
    .showbgcontainer {
      & .sec {
        background-size: cover;
      }
    }

    & .header {
      font-family: "Anton", sans-serif;
      font-size: 4.5rem;
      line-height: 1;

      & .subtitle {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }

      &.lg {
        font-size: 6rem;
        line-height: 1;

        & .subtitle {
          display: block;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;

          font-size: 1.875rem;
          line-height: 2.25rem;
        }
      }
    }

    .greeting-block {
      background-image: url("../../assets/images/static/bg.png");
      background-position: 0 6.2rem;

      & .video-container {
        height: 100%;
        width: 100%;
      }

      & .play-button,
      & .pause-button {
        top: 50%;
      }

      & .header {
        &.lg {
          font-size: 6rem;
          line-height: 1;
        }
      }
    }

    .home-block,
    .desc-block,
    .large-desc-block {
      & .masked-container {
        top: -7rem;
      }
    }

    .story-block {
      & .masked-container {
        top: -6rem;
      }
    }

    .journal-block {
      & .masked-container {
        top: -4rem;
      }
    }

    .island-map-block {
      padding-top: 10rem;

      & .masked-container {
        top: -14rem;
      }
    }

    .md\:rounded-box {
      border-radius: 1rem;
    }

    .parallax {
      & .faqs {
        & .header {
          font-size: 3.75rem;
          line-height: 1;

          & .subtitle {
            font-size: 1.875rem;
            line-height: 2.25rem;
          }
        }

        & .faq {
          font-size: 1.25rem;
          line-height: 1.75rem;
        }

        & .answer {
          font-size: 1rem;
          line-height: 1.25rem;
        }
      }

      & .bg-center {
        background-image: url("../../assets/images/static/51Jordan.png");
      }
    }

    & .god-father-block {
      & .masked-container {
        & .image {
          background-image: url("../../assets/images/static/alien-landing.png");
        }
      }

      & .empty-block {
        height: 100vh;
      }
    }
  }
}

@keyframes scrollGrid {
  0% {
    background-position: 0;
  }
  50% {
    background-position: 200%;
  }
  100% {
    background-position: 400%;
  }
}
