.disciples {
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  font-family: "Anton", sans-serif;
  color: #fff;

  & img {
    width: 100%;
  }

  & .name {
    font-size: 2.25rem;
    line-height: 2.5rem;
    text-align: left;
    padding-top: 1rem;
    padding-bottom: 1rem;

    & .designation {
      display: block;

      text-align: start;
      font-size: 1.25rem;
      line-height: 1.75rem;

      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
  }

  & .desc {
    font-family: WorkSans;
    text-align: start;

    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
